import React from 'react';
import {observer} from "mobx-react-lite";
import ElenaEra from '../assets/elenaera.png';

const SMAbout = () => {
  return (
    <section className='about-course'>
      <div className="about-container">
        <h2 className="about-description-header_alt"><span>Мой опыт работы</span> в области системно - феноменологического метода с 2009 года</h2>
        <div className='about-course-box'>
          <img className='about-elenaera' src={ElenaEra} alt='Елена Эра' />
          <div className='about-description-box'>
            <h2 className="about-description-header"><span>Мой опыт работы</span> в области системно - феноменологического метода с 2009 года</h2>
            <div className='about-description-block'>
              <div className='about-description-block-item'>
                <p className='about-description-block-text'>Эти исследования систем на основе своего рода пришёл в мою жизнь, и буквально оставил меня на этой планете. Хотя появился на основе многолетних знаний и практики в реинкарнационной терапии (с 2000 г.), затем получил расширение, благодаря погружения в Юнгианский Анализ, и приобрёл целостный подход, благодаря большой практике в методе Тета - Хилинг (с 2015 г).</p>
                <p className='about-description-block-text'>На основе осознанной работы в энергетической практике «Сияющие Руки» семинар приобрёл удивительную легкость в трансформации всего того, что выявлено как «тяжелое» в энергию Безусловной Любви.</p>
              </div>
              <div className='about-description-block-item_alt'>
                <p className='about-description-block-text'>Это ощущается и является реальным опытом участников каждого семинара.</p>
                <p className='about-description-block-text'>Это те варианты развития великолепного будущего, которые были закрыты до того, пока каждый участник не набрался смелости произнести То, что он готов изменить в своей жизни, что готов превратить в безграничный источник Создания.</p>
                <p className='about-description-block-text'>Каждый раз я вижу удивление, похожее на потрясение от неожиданных решений, простоты и масштаба возможностей этого семинара.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
};

export default observer(SMAbout);