import React from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';

const SMF = () => {
    return (
      <section className="feedback">
        <div className="container">
          <h2 className="dark_header limit-header"><span>Осознанность подразумевает </span> целостность видения и создания</h2>
          <p className='text-landing'>В процессе семинара, я сопровождаю целостный процесс для всех, и для каждого участника</p>
          <p className='text-landing'><span>Я создам такую атмосферу, при которой вы сможете чувствовать себя в безопасности.</span> Мой многолетний опыт работы и бережно отношение не допустит  «ретравмотизации».</p>
          <p className='text-landing'>Я буду говорить с Вами на вашем языке, кем бы ни были. Вы получите значительно больше, чем возможно рассчитывали получить. Потому что, <span>если Вы увидите свои крылья, я не стану удерживать Вас от этой реальности.</span></p>
        </div>
      </section>
    );
};

export default observer(SMF);