import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import IndexPage from "./pages/index-page";
import HomeMain from "./components/pages/home-main";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<IndexPage />}>
          <Route path="/" element={<HomeMain />} />
          <Route path="*" element={<p>404</p>}></Route>
        </Route>
      </Routes>
    </Router>)
}

export default App;