import $api from "../http";
import {AxiosResponse} from 'axios';
//import {AuthResponse} from "../models/response/AuthResponse";

export default class AuthService {
    static async login(email, password) {
        return $api.post('/u/login', {email, password})
    }

    static async registration(email, firstName, lastName, role, password1) {
        return $api.post('/u/register', {email, firstName, lastName, role, password1})
    }

    static async registrationPractic(email, firstName, lastName, surName, selectCountry, selectCity, description, vk, tg, wp, role, password1, roleType) {
        return $api.post('/u/registerpractic', {email, firstName, lastName, surName, selectCountry, selectCity, description, vk, tg, wp, role, password1, roleType})
    }

    static async restore(email) {
        return $api.post('/u/restore', {email})
    }

    static async logout() {
        return $api.post('/u/logout')
    }

}

