import React from 'react';
import { observer } from "mobx-react-lite";
import { ToastContainer } from 'react-toastify';
import Header from '../components/header';
import Footer from '../components/footer';
import { Outlet } from 'react-router-dom';
import '../style.css';

const IndexPage = () => {

    return (
      <>
        <ToastContainer />
        <Header />
        <Outlet />
        <Footer />
      </>
    );
};

export default observer(IndexPage);