import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import titleApp from '../../services/title';
import SMM from '../../components/section-main-main';
import SMC from '../../components/section-main-courses';
import SMF from '../../components/section-main-feedback';
import SLC from '../section-landing-cost';
import SectionMainAbout from '../section-main-about';
import CookieConsent, { Cookies } from "react-cookie-consent";



const HomePage = () => {

  useEffect(() => {
    document.title = `${titleApp()}`
    document.querySelector('footer').style = 'background: #F7F7F5;';
    document.body.style = 'background: #F7F7F5;';
  }, []);

    return (
      <>
        <SMM />
        <SMC />
        <SectionMainAbout />
        <SMF />
        <SLC />
        <CookieConsent
          location="bottom"
          buttonText="Понятно"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#7788BB", fontSize: "15px", fontFamily: 'Vela Sans GX', fontWeight: '400' }}
          buttonStyle={{ color: "#FFF", fontSize: "15px", fontFamily: 'Vela Sans GX', fontWeight: '400', background: 'none', border: '1px solid', borderRadius: '12px' }}
          expires={150}
        >
          <svg style={{verticalAlign: 'text-top', marginRight: '10px'}} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cookie" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M8 13v.01"></path>
            <path d="M12 17v.01"></path>
            <path d="M12 12v.01"></path>
            <path d="M16 14v.01"></path>
            <path d="M11 8v.01"></path>
            <path d="M13.148 3.476l2.667 1.104a4 4 0 0 0 4.656 6.14l.053 .132a3 3 0 0 1 0 2.296c-.497 .786 -.838 1.404 -1.024 1.852c-.189 .456 -.409 1.194 -.66 2.216a3 3 0 0 1 -1.624 1.623c-1.048 .263 -1.787 .483 -2.216 .661c-.475 .197 -1.092 .538 -1.852 1.024a3 3 0 0 1 -2.296 0c-.802 -.503 -1.419 -.844 -1.852 -1.024c-.471 -.195 -1.21 -.415 -2.216 -.66a3 3 0 0 1 -1.623 -1.624c-.265 -1.052 -.485 -1.79 -.661 -2.216c-.198 -.479 -.54 -1.096 -1.024 -1.852a3 3 0 0 1 0 -2.296c.48 -.744 .82 -1.361 1.024 -1.852c.171 -.413 .391 -1.152 .66 -2.216a3 3 0 0 1 1.624 -1.623c1.032 -.256 1.77 -.476 2.216 -.661c.458 -.19 1.075 -.531 1.852 -1.024a3 3 0 0 1 2.296 0z"></path>
          </svg>
          <span style={{verticalAlign: 'middle'}}>Для работы сайта мы используем файлы cookie. Продолжив работу с сайтом, вы соглашаетесь с Политикой обработки персональных данных и Правилами пользования сайтом.</span>{" "}
        </CookieConsent>
      </>
    );
};

export default observer(HomePage);