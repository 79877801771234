import React from 'react';
import {observer} from "mobx-react-lite";

const SMC = () => {
  return (
    <section className="courses">
      <div className="container">
        <h2 className="dark_header"><span>Для кого подойдет </span> семинар?</h2>
        <div className="courses-block">
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Семинар «Ничего, кроме Любви» даст тем, кто не находит решений на генетическом уровне, увидеть большее на том уровне, где была создана проблема.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Для тех, кто привык мыслить большими категориями и хотел бы больше иметь обыкновенного земного счастья проживания, поможет увидеть красивое по своей простоте решение совсем рядом.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Для тех, кто устал от раскопок или «застрял» в бесконечности изменений, которые больше не приносят ожидаемых результатов, увидеть своими глазами масштаб вашей души и её работу на Планете.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Практики метода «Сияющие Руки» умеющие отличать энергетические импланты чувств и мыслей в тонких телах, могут своими глазами увидеть какие истории человечества и Вселенной стоят за нежными ощущениями движений рук в «Сияющих Сессиях». И по новому увидеть свою истинную Силу Создания, буквально по мановению руки.</div>
              </div>
            </div>
          </div>

        </div>    
      </div>
    </section>
    );
};

export default observer(SMC);